<script lang="ts">
    import HomeImageCard from '$lib/components/cards/HomeImageCard.svelte';
    import HomeTextCard from '$lib/components/cards/HomeTextCard.svelte';
    import { C9_SERVICE_GROUP_PAGES } from '$lib/config/config-pages';
    import type { HomeImage } from '$lib/models/image.models';
    import { loadedServiceGroup } from '$lib/store/navigation.store';
    import { onMount } from 'svelte';

    const imageCards: Record<string, HomeImage> = {
        i1: {
            alt: 'home.image.alt.educational',
            title: 'label.home.img.educational',
            ...C9_SERVICE_GROUP_PAGES['service.educational.activities'],
        },
        i2: {
            alt: 'home.image.alt.health',
            title: 'service.health.doctors',
            ...C9_SERVICE_GROUP_PAGES['service.health.doctors'],
        },
        i3: {
            alt: 'home.image.alt.transport',
            title: 'service.transport.passengers',
            ...C9_SERVICE_GROUP_PAGES['service.transport.passengers'],
        },
        i5: {
            alt: 'home.image.alt.estate',
            title: 'service.agencies.estate',
            ...C9_SERVICE_GROUP_PAGES['service.agencies.estate'],
        },
        i6: {
            alt: 'home.image.alt.eating',
            title: 'service.entertainment.eating',
            ...C9_SERVICE_GROUP_PAGES['service.entertainment.eating'],
        },
        i7: {
            alt: 'home.image.alt.babysitting',
            title: 'service.home.babysitting',
            ...C9_SERVICE_GROUP_PAGES['service.home.babysitting'],
        },
        i8: {
            alt: 'home.image.alt.building',
            title: 'service.improve.building',
            ...C9_SERVICE_GROUP_PAGES['service.improve.building'],
        },
        i9: {
            alt: 'home.image.alt.catering',
            title: 'service.entertainment.catering',
            ...C9_SERVICE_GROUP_PAGES['service.entertainment.catering'],
        },
        i10: {
            alt: 'home.image.alt.juridical',
            title: 'label.home.img.juridical',
            ...C9_SERVICE_GROUP_PAGES['service.admin.juridical'],
        },
        i11: {
            alt: 'home.image.alt.cleaning',
            title: 'service.home.cleaning',
            ...C9_SERVICE_GROUP_PAGES['service.home.cleaning'],
        },
        i14: {
            alt: 'home.image.alt.accountant',
            title: 'service.admin.accountant',
            ...C9_SERVICE_GROUP_PAGES['service.admin.accountant'],
        },
    };

    const textCards = {
        c1: {
            txt: 'home.text.card.c1.txt',
            signed: 'home.text.card.c1.signed',
            place: 'home.text.card.c1.place',
            bgClass: 'bg-blue-800 bg-opacity-75',
            roundClass: 'h-rounded-tl',
        },
        c2: {
            txt: 'home.text.card.c2.txt',
            signed: 'home.text.card.c2.signed',
            place: 'home.text.card.c2.place',
            bgClass: 'bg-teal-900 bg-opacity-50',
            roundClass: '',
        },
        c3: {
            txt: 'home.text.card.c3.txt',
            signed: 'home.text.card.c3.signed',
            place: 'home.text.card.c3.place',
            bgClass: 'bg-green-900 ',
            roundClass: 'h-rounded-br',
        },
    };

    onMount(async () => {
        loadedServiceGroup.set('');
    });
</script>

<style lang="postcss">
    .h-image-row {
        @apply flex flex-row items-stretch;
    }

    .h-card-size {
        @apply w-full px-2;
    }

    .h-card-hide {
        display: inherit;
    }

    @media (min-width: 768px) {
        .md\:w-images-right {
            width: 45.3222%;
        }

        .md\:w-images-left {
            width: 64.6778%;
        }

        .h-card-size {
            @apply w-1/2;
        }

        .h-card-hide {
            @apply hidden;
        }
    }

    @media (min-width: 1048px) {
        .h-card-size {
            @apply w-1/3;
        }

        .h-card-hide {
            display: inherit;
        }
    }
</style>

<div class="w-full flex flex-col">
    <div class="flex items-center justify-center">
        <div class="w-full md:flex md:flex-row auto md:items-stretch">
            <div class="w-full md:w-images-left md:flex-1 md:pr-3">
                <div class="h-image-row pb-3">
                    <div class="flex-1 w-1/2 pr-3">
                        <HomeImageCard class="top-0 right-0 text-gray-200" image={imageCards.i8} />
                    </div>
                    <div class="flex-2 w-1/2">
                        <HomeImageCard class="bottom-0 left-0 text-gray-500" image={imageCards.i2} />
                    </div>
                </div>
                <div class="w-full pb-3">
                    <HomeImageCard class="bottom-0 right-0 text-gray-200" image={imageCards.i6} />
                </div>
            </div>
            <div class="w-full md:w-images-right md:flex-2">
                <div class="w-full pb-3" style="padding-bottom: 0.71rem;">
                    <HomeImageCard class="top-0 left-0 text-gray-300" image={imageCards.i3} />
                </div>
                <div class="h-image-row pb-3">
                    <div class="flex-1 w-4/12 pr-3">
                        <HomeImageCard class="bottom-0 left-0 text-gray-200" image={imageCards.i1} />
                    </div>
                    <div class="flex-2 w-8/12">
                        <HomeImageCard class="top-0 right-0 text-gray-200" image={imageCards.i7} />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="flex items-center justify-center">
        <div class="w-full md:flex md:flex-row auto md:items-stretch">
            <div class="w-full md:w-2/5 md:flex-1 pb-3 md:pr-3">
                <div class="h-image-row h-full">
                    <div class="flex-1 w-1/2 pr-3">
                        <HomeImageCard class="bottom-0 right-0 text-gray-200" image={imageCards.i10} />
                    </div>
                    <div class="flex-2 w-1/2">
                        <HomeImageCard class="top-0 left-0 text-gray-200" image={imageCards.i14} />
                    </div>
                </div>
            </div>
            <div class="w-full md:w-2/5 md:flex-2 pb-3 md:pr-3">
                <div class="h-image-row h-full">
                    <div class="flex-1 w-1/2 pr-3">
                        <HomeImageCard class="top-0 left-0 text-gray-200" image={imageCards.i5} />
                    </div>
                    <div class="flex-2 w-1/2">
                        <HomeImageCard class="bottom-0 right-0 text-gray-200" image={imageCards.i9} />
                    </div>
                </div>
            </div>
            <div class="hidden md:block md:flex-2 md:w-1/5 md: pb-3">
                <HomeImageCard class="" image={imageCards.i11} />
            </div>
        </div>
    </div>
    <div class="flex items-center justify-center">
        <div class="hidden md:flex">
            <div class="h-card-size">
                <HomeTextCard card={textCards.c1} />
            </div>
            <div class="h-card-size h-card-hide">
                <HomeTextCard card={textCards.c2} />
            </div>
            <div class="h-card-size">
                <HomeTextCard card={textCards.c3} />
            </div>
        </div>
    </div>
</div>
